<template>
  <div class="wrap wrapQuery" style="height: 100vh;background: #F8F8F8;">   
    <van-form input-align="right" error-message-align="right">
      <van-field readonly label="项目号" :value="list.project_no" />
      <van-field readonly label="姓名" :value="list.fullname" />
      <van-field readonly label="申请时间" :value="list.endtime" />
      <van-field readonly rows="1" autosize label="购车门店" type="textarea" :value="list.orgname" />
    </van-form>
    <van-steps class="vanStep" direction="vertical" :active="active" active-color="#E50012">
      <van-step>
        <!-- <h3>信审</h3> -->
        <p>信审</p>
      </van-step>
      <van-step>
        <p>合同签约</p>
      </van-step>
      <van-step>
        <p>合同审核</p>
      </van-step>
      <van-step>
        <p>合同激活</p>
      </van-step>
      <van-step>
        <p>合同结束</p>
      </van-step>
    </van-steps>
  </div>
</template>
<script>
  import {orderDetail,getOpenId} from '@/server'
  import { Toast,Step, Steps } from 'vant';
  import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  export default {
  data() {
    return {
      projectNo: this.$route.query.projectNo,
      projectStatus: this.$route.query.projectStatus,
      cardId:this.$store.state.cardId,
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      period: this.$route.query.period,
      list: {},
      active:''
    };
  },
  mounted(){
    let that = this;
    sessionStorage.setItem('key', 'applyQ3');
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    	if(sessionStorage.getItem('key') == 'applyQ3') {
        that.$router.push({ path: '/applyQuery' });
      }
    }, false);
  },
  created(){
    this.getDetail()
    //如果有openid
    // if(this.openId){
    //   getOpenId({openid:this.openId}).then(res=>{
    //     if(res.wx_code=='0'){
    //       this.$store.commit('bindStatus', res.bind_status);
    //       localStorage.setItem("bindStatus", res.bind_status);
    //       localStorage.setItem("accessToken", res.access_token);
    //       this.$store.commit('accessToken', res.access_token);
    //       localStorage.setItem("openId", res.openid);
    //       this.$store.commit('openId', res.openid);
    //       this.$store.commit('cardId', res.card_id);
    //       localStorage.setItem("cardId", res.card_id);
    //       if(res.bind_status !=='1'){
    //         this.$router.push({ path: '/login?redirect=/rentPlanPay' });
    //         alert("aaa")
    //       }else{
    //         this.getDetail();
    //       }
    //     }else{
    //         Toast.fail({
    //           message: res.msg,
    //           duration:'3000'
    //         });
    //       }
    //   })
    // } else{
    //   if(!this.code) {
    //     this.$store.commit('bindStatus', '1');
    //     localStorage.setItem("bindStatus", '1');
    //     let uiCode = encodeURIComponent(domainUrl+'/rentPlanPay');
    //     window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
    //   }else{//获取code
    //     getOpenId({percode:this.code}).then(res=>{
    //       if(res.wx_code=='0'){
    //         this.$store.commit('bindStatus', res.bind_status);
    //         localStorage.setItem("bindStatus", res.bind_status);
    //         this.$store.commit('accessToken', res.access_token);
    //         localStorage.setItem("accessToken", res.access_token);
    //         this.$store.commit('openId', res.openid);
    //         localStorage.setItem("openId", res.openid);
    //         this.$store.commit('cardId', res.card_id);
    //         localStorage.setItem("cardId", res.card_id);
    //         if(res.bind_status !=='1'){
    //           this.$router.push({ path: '/login?redirect=/rentPlanPay' });
    //         }else{
    //           this.getDetail();
    //         }
    //       }else{
    //         Toast.fail({
    //           message: res.msg,
    //           duration:'3000'
    //         });
    //       }
    //     });
    //   }
    // }
  },
  methods: {
    getDetail(){
      const params = {
        card_id: this.cardId,
        project_no: this.projectNo,
        project_status: this.projectStatus
      }
      orderDetail(params).then(res=>{
        this.list = res.data[0];
        var flowName = res.data[0].flowname;
        if(flowName == '信审'){
          this.active = '0';
        }else if(flowName == '合同签约'){
          this.active = '1';
        }else if(flowName == '合同审核'){
          this.active = '2';
        }else if(flowName == '合同激活'){
          this.active = '3';
        }else if(flowName == '合同结束'){
          this.active = '4';
        }
      })
    }
  },
};
</script>
<style>
textarea{
  overflow:hidden;
}
.wrap .van-field__control {
    color: #666666 !important;
}
.upflie .van-cell__value{
  color: #666666 !important;
}
.upflie .van-cell__title{
  color: #646566;
}
.wrapQuery .van-field--min-height .van-field__control {
  min-height: 30px;
}
.vanStep{
  margin: 15px;border-radius: 5px;
}
.van-step--finish{
  color: #E50012;
}
</style>